import { Result } from 'antd';

const Page401 = () => (
	<Result
		status="403"
		title="401"
		subTitle="Sorry, you are not authorized. Log in to your profile to continue."
	/>
);

export default Page401;
