import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRouteAdmin, PrivateRouteVisitor } from 'components/PrivateRoute';

const Page401 = lazy(() => import('./pages/Page401'));
const Page403 = lazy(() => import('./pages/Page403'));
const Page404 = lazy(() => import('./pages/Page404'));
const AdminLoginPage = lazy(() => import('./pages/admin/LoginPage'));
const AdminFunnelsPage = lazy(() => import('./pages/admin/FunnelsPage'));
const AdminFunnelPage = lazy(() => import('./pages/admin/FunnelItemPage'));
const FunnelSignupPage = lazy(() => import('./pages/visitor/FunnelSignupPage'));
const SignUpThankYouPage = lazy(() => import('./pages/visitor/SignUpThankYouPage'));
const SurveyPage = lazy(() => import('./pages/visitor/SurveyPage'));
const BenefitPage = lazy(() => import('./pages/visitor/BenefitPage'));
const PricingPage = lazy(() => import('./pages/visitor/PricingPage'));
const PreloadAuth = lazy(() => import('./pages/visitor/PreloadAuth'));
const VisitorSettings = lazy(() => import('./pages/visitor/Settings'));
const PaymentError = lazy(() => import('./pages/visitor/PaymentError'));
const ThankYouPurchase = lazy(() => import('./pages/visitor/ThankYouPurchase'));

const Routes = ({ user, setUser }) => (
	<Switch>
		<PrivateRouteAdmin exact user={user} path="/admin/funnels" component={AdminFunnelsPage} />
		<PrivateRouteAdmin exact user={user} path="/admin/funnels/:id" component={AdminFunnelPage} />
		<Route exact path="/:funnelslug/thankyou" render={() => <SignUpThankYouPage />} />
		<PrivateRouteVisitor exact user={user} path="/:funnelslug/benefit" component={BenefitPage} />
		<PrivateRouteVisitor exact user={user} path="/:funnelslug/pricing" component={PricingPage} />
		<PrivateRouteVisitor exact user={user} path="/settings" component={VisitorSettings} />
		<PrivateRouteVisitor
			exact
			user={user}
			component={ThankYouPurchase}
			path="/funnel/purchase/thankyou"
		/>
		<PrivateRouteVisitor exact user={user} component={PaymentError} path="/funnel/purchase/error" />

		<PrivateRouteVisitor
			exact
			user={user}
			path="/:funnelslug/survey/:page/:question"
			component={SurveyPage}
		/>
		<Route
			exact
			path="/:funnelslug/authorization/:token"
			render={() => <PreloadAuth setUser={setUser} />}
		/>
		<Route
			exact
			path="/admin/login"
			render={props => <AdminLoginPage setUser={setUser} {...props} />}
		/>
		<Route
			exact
			path="/:funnelslug/signup"
			render={props => <FunnelSignupPage setUser={setUser} {...props} />}
		/>

		<Route exact path="/401" render={props => <Page401 {...props} />} />
		<Route exact path="/403" render={props => <Page403 {...props} />} />
		<Route exact path="/404" render={props => <Page404 {...props} />} />
		<Route component={Page404} />
	</Switch>
);

export default Routes;
