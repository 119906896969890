import jwt_decode from 'jwt-decode';
import { create, all } from 'mathjs';

const config = {};
const math = create(all, config);

export const decodeJWT = token => {
	try {
		if (token) {
			const parsed = token.slice(7);
			return jwt_decode(parsed);
		}
		return null;
	} catch (e) {
		return null;
	}
};

export const changeOrder = (index, list, top) => {
	if (top) {
		const elem = list[index];
		const elem2 = list[index - 1];
		return [
			{ id: elem._id, order: elem2.order },
			{ id: elem2._id, order: elem.order },
		];
	}

	const elem = list[index];
	const elem2 = list[index + 1];
	return [
		{ id: elem._id, order: elem2.order },
		{ id: elem2._id, order: elem.order },
	];
};

export const getCreateTableItemState = tableColumns => {
	const result = {};

	tableColumns.forEach(el => {
		result[el.identifier] = {
			text: '',
			column: el.name,
			valueFormula: '',
			columnId: el.identifier,
		};
	});

	return result;
};

export const getEditTableItemState = (data, columns) => {
	const result = {};

	data.forEach(({ __typename, ...el }) => {
		result[el.columnId] = { ...el };
	});

	columns.forEach(el => {
		if (!result[el.identifier]) {
			result[el.identifier] = {
				text: '',
				column: el.name,
				valueFormula: '',
				columnId: el.identifier,
			};
		}
	});

	return result;
};

export const createTableItemObjToArray = object => {
	return Object.keys(object).map(key => ({
		...object[key],
		columnId: key,
	}));
};

export const formulaParserPricing = (formula, variables, lineItems, slides, survey) => {
	try {
		const result = [];
		const normalFormula = formula.replace(/({|})/gm, '');
		const items = normalFormula.split('.');
		if (items[0] === '@variables') {
			const varbl = variables.find(el => el.identifier === items[1]);
			return varbl ? `${varbl.value} ${varbl.plural}` : '';
		}
		if (items[0] === '@blockItem') {
			const q = findBlocks(normalFormula, [], variables, [], lineItems, lineItems, survey);
			result.push(q);
		}
		if (items[0] === '@finalBenefit') {
			const slide = slides.find(el => el.identifier === items[1]);
			return slide ? slide.value : '';
		}

		if (result.length > 0) {
			const formulaResult = math.evaluate(result.flat().join(''));
			const withPoint = formulaResult.toFixed(2);
			const withoutPoint = formulaResult.toFixed();
			return withPoint % withoutPoint === 0 ? withoutPoint : withPoint;
		}
		return 'N/A';
	} catch (err) {
		return 'N/A';
	}
};

const checkVars = ['@variables', '@column', '@lineItem', '@survey', '@blockItem'];
export const validFormulaVariables = (
	formula,
	variables,
	columns,
	lineItems,
	allLines,
	surveys,
) => {
	const status = true;
	const testStr = formula;
	const formulas = testStr?.match(/@[A-Z,a-z,0-9,.]*/gi);
	if (!formulas || formulas.length === 0) return true;

	for (let i = 0; i < formulas.length; i += 1) {
		const items = formulas[i].split('.');

		if (!checkVars.includes(items[0])) return false;

		if (!variables && items[0] === '@variables') return false;
		if (items[0] === '@variables') {
			const indx = variables.findIndex(e => e.identifier === items[1]);
			if (indx === -1) return false;
		}

		if (!columns && items[0] === '@column') return false;
		if (items[0] === '@column') {
			const indx = columns.findIndex(e => e.identifier === items[1]);
			if (indx === -1) return false;
		}

		if (!lineItems && items[0] === '@lineItem') return false;
		if (items[0] === '@lineItem') {
			const indx = lineItems.findIndex(e => e.identifier === items[1]);
			if (indx === -1) return false;
			const indx2 = columns.findIndex(e => e.identifier === items[2]);
			if (indx2 === -1) return false;
		}

		if (!surveys && items[0] === '@survey') return false;
		if (items[0] === '@survey') {
			const indx = surveys.findIndex(e => e.identifier === items[1]);
			if (indx === -1) return false;
			const indx2 = surveys.findIndex(e => {
				const j = e.questions.findIndex(el => el.identifier === items[2]);
				return j !== -1;
			});
			if (indx2 === -1) return false;
		}

		if (!allLines && items[0] === '@blockItem') return false;
		if (items[0] === '@blockItem') {
			const indx = allLines.findIndex(e => e.identifier === items[1]);
			if (indx === -1) return false;
			const indx2 = allLines.findIndex(e => {
				const j = e.lines.findIndex(el => el.identifier === items[2]);
				return j !== -1;
			});
			if (indx2 === -1) return false;
			const indx3 = allLines.findIndex(e => {
				const j = e.lines.findIndex(el => {
					const j2 = el.data.findIndex(data => data.columnId === items[3]);
					return j2 !== -1;
				});
				return j !== -1;
			});
			if (indx3 === -1) return false;
		}
	}
	return status;
};

export const validFormulaInArray = (arr, variables, columns, lineItems, surveys) => {
	for (let i = 0; i < arr.length; i += 1) {
		const item = arr[i].valueFormula;
		const status = validFormulaVariables(item, variables, columns, lineItems, null, surveys);
		if (!status) {
			return false;
		}
	}
	return true;
};

export const formulaParser = (
	lineItem,
	variables,
	column,
	lineItems,
	calculationBlocks,
	summary,
	survey,
) => {
	try {
		const testStr = lineItem.valueFormula;
		let replaceStr = lineItem.valueFormula;
		const formulas = testStr.match(/(@[A-Z,a-z,0-9,\.]*)|[0-9]*/gi).filter(e => {
			return e.replace(/(\r\n|\n|\r)/gm, '');
		});

		if (formulas?.length === 0 && (replaceStr === '' || Number.isNaN(+replaceStr))) {
			return lineItem.text;
		}
		if (formulas?.length === 0 && !Number.isNaN(+replaceStr)) return replaceStr;

		const result = [];
		for (let i = 0; i < formulas.length; i += 1) {
			const formulaItem = formulas[i].split('.');
			if (formulaItem[0] === '@variables') {
				const v = findVariables(formulas[i], variables);
				replaceStr = replaceStr.replace(formulas[i], v);
				result.push(v);
			}
			if (formulaItem[0] === '@column') {
				const c = findColumn(formulas[i], variables, column, lineItems, survey);
				replaceStr = replaceStr.replace(formulas[i], c);
				result.push(c);
			}
			if (formulaItem[0] === '@lineItem') {
				const q = findLines(formulas[i], variables, column, lineItems, survey);
				replaceStr = replaceStr.replace(formulas[i], q);
				result.push(q);
			}
			if (formulaItem[0] === '@blockItem') {
				const q = findBlocks(
					formulas[i],
					calculationBlocks,
					variables,
					column,
					lineItems,
					summary,
					survey,
				);
				replaceStr = replaceStr.replace(formulas[i], q);
				result.push(q);
			}
			if (formulaItem[0] === '@survey') {
				const s = findSurvey(formulas[i], survey);
				replaceStr = replaceStr.replace(formulas[i], s);
			}
		}

		// console.log(replaceStr);
		const formulaResult = math.evaluate(replaceStr);
		if (formulaResult) {
			const withPoint = formulaResult.toFixed(2);
			const withoutPoint = formulaResult.toFixed();
			return withPoint % withoutPoint === 0 ? formulaResult : withPoint;
		}
		return '';
	} catch (e) {
		return 'N/A';
	}
};

export const parserFormulaForSurvey = (formula, variables, survey) => {
	try {
		const formulas = formula.match(/(@[A-Z,a-z,0-9,\.]*)|[0-9]*/gi).filter(e => {
			return e.replace(/(\r\n|\n|\r)/gm, '');
		});
		let replaceStr = formula;
		for (let i = 0; i < formulas.length; i += 1) {
			const formulaItem = formulas[i].split('.');
			if (formulaItem[0] === '@variables') {
				const v = findVariables(formulas[i], variables);
				replaceStr = replaceStr.replace(formulas[i], v);
			}
			if (formulaItem[0] === '@survey') {
				const s = findSurvey(formulas[i], survey);
				replaceStr = replaceStr.replace(formulas[i], s);
			}
		}
		const formulaResult = math.evaluate(replaceStr);
		if (formulaResult) {
			const withPoint = formulaResult.toFixed(2);
			const withoutPoint = formulaResult.toFixed();
			return withPoint % withoutPoint === 0 ? formulaResult : withPoint;
		}
		return '';
	} catch (e) {
		return 'N/A';
	}
};

export const findVariables = (formulaVariable, variables) => {
	const item = formulaVariable.split('.');
	const findVariable = variables.find(el => el.identifier === item[1]);
	return +findVariable.value;
};
const findColumn = (formulaColumn, variables, columns, lineItems, survey) => {
	const itemColumn = formulaColumn.split('.');
	const column = columns.find(el => el.columnId === itemColumn[1]);
	const formula = column.valueFormula;
	const variableArray = [];
	let replaceStr = column.valueFormula;
	if (isNaN(parseInt(formula))) {
		const parseFormula = formula.match(/(@[A-Z,a-z,0-9,\.]*)|[0-9]*/gi).filter(e => {
			return e.replace(/(\r\n|\n|\r)/gm, '');
		});
		for (let i = 0; i < parseFormula.length; i += 1) {
			const item = parseFormula[i].split('.');
			if (item[0] === '@variables') {
				const v = findVariables(parseFormula[i], variables);
				replaceStr = replaceStr.replace(parseFormula[i], v);
				variableArray.push(v);
			}
			if (item[0] === '@column') {
				const q = findColumn(parseFormula[i], variables, columns, lineItems, survey);
				replaceStr = replaceStr.replace(parseFormula[i], q);
				variableArray.push(q);
			}
			if (item[0] === '@lineItem') {
				const v = findLines(parseFormula[i], variables, column, lineItems);
				replaceStr = replaceStr.replace(parseFormula[i], v);
				variableArray.push(v);
			}
			if (item[0] === '@survey') {
				const s = findSurvey(parseFormula[i], survey);
				replaceStr = replaceStr.replace(parseFormula[i], s);
			}
		}
		return math.evaluate(replaceStr);
	}
	return parseInt(formula);
};

const findLines = (formulaLine, variables, columns, lines, survey) => {
	const itemLine = formulaLine.split('.');
	const findLine = lines.find(el => el.identifier === itemLine[1]);
	const findColumns = findLine.data.find(el => el.columnId === itemLine[2]);
	const formula = findColumns.valueFormula;
	const massVariable = [];
	let replaceStr = findColumns.valueFormula;
	if (isNaN(parseInt(formula))) {
		const parseFormula = formula.match(/(@[A-Z,a-z,0-9,\.]*)|[0-9]*/gi).filter(e => {
			return e.replace(/(\r\n|\n|\r)/gm, '');
		});
		for (let i = 0; i < parseFormula.length; i += 1) {
			const item = parseFormula[i].split('.');
			if (item[0] === '@variables') {
				const colm = findVariables(parseFormula[i], variables);
				replaceStr = replaceStr.replace(parseFormula[i], colm);
				massVariable.push(colm);
			}
			if (item[0] === '@column') {
				const cl = findColumn(parseFormula[i], variables, findLine.data, lines);
				replaceStr = replaceStr.replace(parseFormula[i], cl);
				massVariable.push(cl);
			}
			if (item[0] === '@survey') {
				const s = findSurvey(parseFormula[i], survey);
				replaceStr = replaceStr.replace(parseFormula[i], s);
			}
			if (item[0] === '@lineItem') {
				const q = findLines(parseFormula[i], variables, null, lines, survey);
				replaceStr = replaceStr.replace(parseFormula[i], q);
			}
		}
		return math.evaluate(replaceStr);
	}
	return parseInt(formula);
};

const findBlocks = (formula, calcBlocks, variables, column, lineItem, summaryLineItem, survey) => {
	const item = formula.split('.');
	const findLine = lineItem.find(el => el.identifier === item[2]);
	const findLineItem = summaryLineItem.find(el => el.identifier === item[2]);
	const findColumns =
		findLineItem?.data.find(el => el.columnId === item[3]) ||
		findLine?.data.find(el => el.columnId === item[3]);
	const formulaItem = findColumns.valueFormula;
	const massSliderVariables = [];
	let replaceStr = findColumns.valueFormula;
	if (isNaN(parseInt(formulaItem))) {
		const parseFormula = formulaItem.match(/(@[A-Z,a-z,0-9,\.]*)|[0-9]*/gi).filter(e => {
			return e.replace(/(\r\n|\n|\r)/gm, '');
		});
		for (let i = 0; i < parseFormula.length; i += 1) {
			const elem = parseFormula[i].split('.');
			if (elem[0] === '@variables') {
				const v = findVariables(parseFormula[i], variables);
				replaceStr = replaceStr.replace(parseFormula[i], v);
				massSliderVariables.push(v);
			}
			if (elem[0] === '@column') {
				const c = findColumn(parseFormula[i], variables, findLineItem.data, lineItem, survey);
				replaceStr = replaceStr.replace(parseFormula[i], c);
				massSliderVariables.push(c);
			}
			if (elem[0] === '@lineItem') {
				const q = findLines(parseFormula[i], variables, findLineItem.data, lineItem, survey);
				replaceStr = replaceStr.replace(parseFormula[i], q);
				massSliderVariables.push(q);
			}
			if (elem[0] === '@survey') {
				const s = findSurvey(parseFormula[i], survey);
				replaceStr = replaceStr.replace(parseFormula[i], s);
			}
		}
		return math.evaluate(replaceStr);
	}
	return parseInt(formulaItem);
};
const findSurvey = (formula, survey) => {
	const item = formula.split('.');
	const surveyV = survey.find(el => el.page === item[1] && el.identifier === item[2]);
	return +surveyV.value;
};

/** ************************************** */

export const updateAnswer = (answer, identifier, isVariable, variableID, newValue) => {
	const idx = answer.findIndex(el => el.identifier === identifier);
	const oldItem = answer[idx];
	const newItem = { ...oldItem, value: newValue, variable: isVariable, variableId: variableID };
	const newArrayAnswer = [...answer.slice(0, idx), newItem, ...answer.slice(idx + 1)];
	return newArrayAnswer;
};
export const getResult = res => {
	const withPoint = res.toFixed(2);
	const withoutPoint = res.toFixed();
	return withPoint % withoutPoint === 0 ? withoutPoint : withPoint;
};
