export const ROLE_ADMIN = 'admin';
export const ROLE_VISITOR = 'visitor';

export const AUTH_TYPES = {
	LOGIN: 'login',
	REGISTER: 'register',
};

export const BASE_URL =
	process.env.NODE_ENV === 'production'
		? process.env.REACT_APP_BASE_URL
		: process.env.REACT_APP_BASE_URL_DEV;

export const QUESTION = {
	RANGE: 'range',
	SLIDER: 'slider',
	OPTIONS: 'options',
	SLIDER_PERCENT: 'slider-percent',
};

export const FOLLOW_UP = {
	GLOBAL: 'global',
	SURVEY: 'survey',
	PRICING: 'pricing',
	BENEFIT: 'benefit',
};

export const USER_STATUS = {
	SURVEY: 'survey',
	PRICING: 'pricing',
	BENEFIT: 'benefit',
};

export const PRICING_CUSTOMERS = [
	{
		price: 150,
		_id: '0-250',
		identifier: '0 - 250 Mandanten',
	},
	{
		price: 200,
		_id: '251-500',
		identifier: '251 - 500 Mandanten',
	},
	{
		price: 250,
		_id: '501-1000',
		identifier: '501 - 1000 Mandanten',
	},
	{
		price: 350,
		_id: '1001-2000',
		identifier: '1001 - 2000 Mandanten',
	},
];

export const PAYMENT_INTERVAL = [
	{
		interval: 12,
		discount: 5,
		setupPrice: 900,
		initialDiscount: 0,
		text: 'Standard advance \n 5% discount on monthly fee       ',
		plan: {
			[PRICING_CUSTOMERS[0]._id]: '60a4f9062211e944f607aebb',
			[PRICING_CUSTOMERS[1]._id]: '60a52283c95b8399742d9d93',
			[PRICING_CUSTOMERS[2]._id]: '60a5235b7c6e5e36a82e4fd1',
			[PRICING_CUSTOMERS[3]._id]: '60a523d92211e944f6083844',
		},
	},
	{
		interval: 24,
		discount: 10,
		setupPrice: 800,
		initialDiscount: 3,
		text: '3% discount on advance \n 10% discount on monthly fee',
		plan: {
			[PRICING_CUSTOMERS[0]._id]: '60a5284e800bc796092856be',
			[PRICING_CUSTOMERS[1]._id]: '60a52a79c95b8399742da081',
			[PRICING_CUSTOMERS[2]._id]: '60a54670666f61b6a9696078',
			[PRICING_CUSTOMERS[3]._id]: '60a54a1bb2610e081202acce',
		},
	},
	{
		interval: 36,
		discount: 20,
		setupPrice: 700,
		initialDiscount: 4,
		text: '4% discount on advance \n 20% discount on monthly fee',
		plan: {
			[PRICING_CUSTOMERS[0]._id]: '60a54e212211e944f6087f95',
			[PRICING_CUSTOMERS[1]._id]: '60a54f112211e944f6088146',
			[PRICING_CUSTOMERS[2]._id]: '60a55f5f2211e944f6089ebf',
			[PRICING_CUSTOMERS[3]._id]: '60a560382211e944f608a006',
		},
	},
	{
		interval: 48,
		discount: 50,
		setupPrice: 500,
		initialDiscount: 5,
		text: '5% discount on advance \n 50% discount on monthly fee',
		plan: {
			[PRICING_CUSTOMERS[0]._id]: '60a567ff800bc796092870f6',
			[PRICING_CUSTOMERS[1]._id]: '60a56867800bc79609287147',
			[PRICING_CUSTOMERS[2]._id]: '60a56937b2610e081202b89e',
			[PRICING_CUSTOMERS[3]._id]: '60a569b22211e944f608b17b',
		},
	},
];

export const LINK_TO_CALENDAR = 'https://www.google.com/';
export const LINK_LOG_OUT = 'https://www.kanzleipilot.de';
