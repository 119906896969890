import fetch from 'cross-fetch';
import { BrowserRouter } from 'react-router-dom';
import { Suspense, useEffect, useState } from 'react';
import { setContext } from '@apollo/client/link/context';
import { createHttpLink, ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import './App.css';
import Routes from 'Routes';
import { decodeJWT } from 'utils/helpers';
import Preloader from 'components/Preloader';
import { BASE_URL_BACK } from 'services/baseUrl';
import AppLayout from 'components/layout/AppLayout';

const authLink = setContext((_, { headers }) => ({
	headers: {
		...headers,
		authorization: localStorage.getItem('token'),
	},
}));
const httpLink = createHttpLink({ uri: BASE_URL_BACK, fetch });

const client = new ApolloClient({
	cache: new InMemoryCache(),
	link: authLink.concat(httpLink),
});

const App = () => {
	const [user, setUser] = useState('');
	useEffect(() => {
		if (user === '') {
			const token = localStorage.getItem('token');
			const decoded = decodeJWT(token);
			setUser(decoded);
		}
	}, [user]);

	return (
		<AppLayout>
			<BrowserRouter>
				<ApolloProvider client={client}>
					<Suspense fallback={<Preloader />}>
						<Routes user={user} setUser={setUser} />
					</Suspense>
				</ApolloProvider>
			</BrowserRouter>
		</AppLayout>
	);
};

export default App;
