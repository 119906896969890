import { Route } from 'react-router-dom';
import Page403 from 'pages/Page403';
import Page401 from 'pages/Page401';
import { ROLE_ADMIN, ROLE_VISITOR } from 'utils/constants';

const PrivateRouteVisitor = ({ component: Component, user, ...rest }) => (
	<Route
		exact
		{...rest}
		render={props => {
			if (!user || !user?.role) return <Page401 />;
			return user && user?.role === ROLE_VISITOR ? <Component {...props} /> : <Page403 />;
		}}
	/>
);

const PrivateRouteAdmin = ({ component: Component, user, ...rest }) => (
	<Route
		exact
		{...rest}
		render={props => {
			if (!user || !user?.role) return <Page401 />;
			return user && user?.role === ROLE_ADMIN ? <Component {...props} /> : <Page403 />;
		}}
	/>
);

export { PrivateRouteVisitor, PrivateRouteAdmin };
